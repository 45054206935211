<template>
  <router-view />
</template>

<style>
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #2c3e50;
}
</style>
