
import { defineComponent } from "vue";

export default defineComponent({
  name: "PublicFooter",
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
});
