
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "PublicHeader",
  props: {
    active: String,
  },
  setup() {
    const headerNavList = reactive([
      { navName: "Home", navPath: "/", active: "home" },
      { navName: "About Us", navPath: "/about", active: "about" },
      { navName: "Features", navPath: "/feature", active: "features" },
      { navName: "Contact Us", navPath: "/contact", active: "contact" },
    ]);
    return { headerNavList };
  },
});
