import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
      requireAuth: false,
    },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      title: "About us",
      requireAuth: false,
    },
  },
  {
    path: "/feature",
    name: "feature",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FeatureView.vue"),
    meta: {
      title: "feature",
      requireAuth: false,
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PrivacyPolicyView.vue"),
    meta: {
      title: "privacy",
      requireAuth: false,
    },
  },

  {
    path: "/terms",
    name: "terms",
    component: () =>
      import(
        /* webpackChunkName: "terms" */ "../views/TermsConditionsView.vue"
      ),
    meta: {
      title: "Terms & Conditions",
      requireAuth: false,
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
    meta: {
      title: "Contact Us",
      requireAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" }; // Scrolls to the top smoothly on route change
  },
});

router.beforeEach((to) => {
  // Change page title based on route meta
  document.title = to.meta.title ? (to.meta.title as string) : "ReGiftMe";
});

export default router;
